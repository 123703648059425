import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EventNewState } from "../../@types/news";
import eventService from "../../services/event/event.service";

// ----------------------------------------------------------------------

export const getServiceEvent = createAsyncThunk(
  "get/getServiceEvent",
  async (params: any) => {
    const { data } = await eventService.get(params);
    return data;
  }
);
export const getServicePageEvent = createAsyncThunk(
  "get/getServicePageEvent",
  async (params: any) => {
    const { data } = await eventService.get(params);
    return data;
  }
);
export const getNewsEvent = createAsyncThunk(
    "get/getNewsEvent",
    async (params: any) => {
      const { data } = await eventService.get(params);
      return data;
    }
  );

export const getOneEvent = createAsyncThunk(
  "get/getOneEvent",
  async (id: any) => {
    const { data } = await eventService.getOne(id);
    return data;
  }
);

const initialState: EventNewState = {
  services: [],
  servicesPage: [],
  news: [],
   eventDetails:  {
    id: 0,
    name: "",
    description: "string",
    thumbnail:"string",
    content: "string",
    createdAt:"string",
    type:"string"
  },
  serviceCount: 0
};

const slice = createSlice({
  name: "Event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServiceEvent.fulfilled, (state, action) => {
      state.services = action.payload.items;
      state.serviceCount = action.payload.totalRow;
    })
    builder.addCase(getServicePageEvent.fulfilled, (state, action) => {
      state.servicesPage = action.payload.items;
      state.serviceCount = action.payload.totalRow;
    })
    builder.addCase(getNewsEvent.fulfilled, (state, action) => {
        state.news = action.payload.items;
      });   
    builder.addCase(getOneEvent.fulfilled, (state, action) => {
      state.eventDetails = action.payload;
    });
  },
});
export default slice.reducer;
