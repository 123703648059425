import React from "react";
import "./SearchPage.scss";
import { Row } from "antd";
import SearchToolbar from "../../components/views/search-page/search-toolbar/SearchToolbar";

type Props = {};

const SearchPage = (props: Props) => {
  return (
    <div className="containerCustom">
      <div className="search">
        <div className="search__toolbar"></div>
        <SearchToolbar />
      </div>
    </div>
  );
};

export default SearchPage;
