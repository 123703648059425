import React, { useEffect, useState } from "react";
import "./OurServices.scss";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CardComponent from "../../../card-component/CardComponent";
import { Button, Col, Pagination, PaginationProps, Row } from "antd";
import { dispatch, useSelector } from "../../../../redux/store";
import { ROUTE_PATHS } from "../../../../constants/url-config";
import { SERVICE, SERVICE_PAGINATION } from "../../../../constants/app-constants";
import { getServicePageEvent } from "../../../../redux/slices/event";
type Props = {};

const OurServices = (props: Props) => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    pageSize: SERVICE_PAGINATION.pageSize,
    pageIndex: SERVICE_PAGINATION.pageIndex,
  });
  console.log(params,"params");
  const {servicesPage,serviceCount} = useSelector((state)=> state.event)
  useEffect(()=> {
    dispatch(getServicePageEvent({
      pageSize: SERVICE_PAGINATION.pageSize,
      pageIndex: SERVICE_PAGINATION.pageIndex,
        eventType: SERVICE
    }))
  },[])
  const customItemRender: PaginationProps["itemRender"] = (
    current,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <Button
          className="buttonCareers__prev"
          type="primary"
          shape="circle"
          icon={<ArrowLeftOutlined className="careersIcon" />}
        />
      );
    }
    if (type === "next") {
      return (
        <Button
          className="buttonCareers__next"
          type="primary"
          shape="circle"
          icon={<ArrowRightOutlined className="careersIcon" />}
        />
      );
    }
    return originalElement; // For other types, use the default rendering
  };
  const handlePageChange = async (page: number) => {
    setParams({
      ...params,
      pageIndex: page,
    });
    dispatch(getServicePageEvent({
      pageSize: params.pageSize,
      pageIndex: page, // Trang mới
      eventType: SERVICE
    }));

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  return (
    <div className="containerCustom service">
      <div className="ourService">
        <h2 className="title ourService__title" data-aos="zoom-in" data-aos-duration="700">
          {t("servicesPage.content.title")}
        </h2>
      </div>

      <div className="ourService__content">
        <Row
          gutter={[
            { xl: 20, xs: 0 },
            { xl: 40, xs: 24 },
          ]}
        >
          {servicesPage.map(eventService => (
        <Col key={eventService.id} xs={24} xl={8} data-aos="zoom-in-up" data-aos-duration="700">
          <CardComponent
            title={eventService.name}
            content={eventService.description}
            picture={eventService.thumbnail} 
            id={eventService.id}
            href={ROUTE_PATHS.ServiceDetail.replace(":id", eventService.id.toString())}
          />
        </Col>
        
      ))}
      
        </Row>
        <div className="careers__pagination">
                  <Pagination
                    pageSize={params.pageSize}
                    defaultCurrent={1}
                    total={serviceCount}
                    itemRender={customItemRender}
                    onChange={handlePageChange}
                    
                  />
                </div>
      </div>
    </div>
  );
};

export default OurServices;
