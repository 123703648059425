import { Col, Row } from "antd";
import aboutusLayout from "../../../../assets/images/About/abouttop.png";
import aboutusLayoutRespon from "../../../../assets/images/About/aboutusRespon.svg";
import { Typography } from "antd";
import './AboutUs.scss';
import { useTranslation } from "react-i18next";
import useResponsive from "../../../../hooks/useResponsive";
import { IBannerHomePage } from "../../../../@types/homePage";
import MetaLayout from "../../layout/meta-layout";
const { Title, Text } = Typography;
type Props = {};

const AboutUs = ({
  title,
  content,
  backgroundUrl,
}: IBannerHomePage) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const bannerUrl = backgroundUrl ? backgroundUrl : aboutusLayout;
  const metaData = 
   {
      title: title,
      description: content,
      ogImage: backgroundUrl,
      keywords: "",
      ogTitle: title,
      ogDescription: content,
      ogUrl: window.location.href,
  }
  return (
    <>
    {metaData && <MetaLayout metaData={metaData} />}
    <div className="containerCustom aboutus">
      <Row className=" aboutUs aboutUs__top" gutter={[{ xs: 0, xl: 80 }, { xs: 0, xl: 0 }]}>
        <Col span={12}></Col>
        <Col span={12}><Title data-aos="fade-up"
          data-aos-anchor-placement="top-center" data-aos-duration="700" className="title aboutUs__title" level={2}>
            {title?title:t("aboutPage.aboutUs.title")}
            </Title></Col>
      </Row>
      <Row className=" aboutUs" gutter={[{ xs: 0, xl: 80 }, { xs: 32, xl: 0 }]}>
        <Col xs={24} xl={12} data-aos="fade-up"
          data-aos-anchor-placement="top-center" data-aos-duration="700">
            <div className="shine-effect">
          <img src={!isMobile ? bannerUrl : aboutusLayoutRespon} alt="" />
          </div>
        </Col>
        <Col xs={24} xl={12} data-aos="fade-down"
          data-aos-anchor-placement="top-center" data-aos-duration="700">
        <h3 className="aboutUs__desc" 
        dangerouslySetInnerHTML={{ __html: content }} >
        </h3>
        </Col>
      </Row>
    </div>
    </>
  );
};
export default AboutUs;
