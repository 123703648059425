import { RouteChildrenProps } from "react-router";
import { LayoutProps } from "../../components/views/layout/route-layout";
interface Props extends RouteChildrenProps, LayoutProps {}

export default function PayrollPage(props: Props) {
  return (
      <div>
     PageRoll
      </div>
  );
}
