import { RouteChildrenProps } from "react-router";
import { LayoutProps } from "../../components/views/layout/route-layout";
import "./home.scss";
import banner from "../../assets/images/layout.png";
import { useTranslation } from "react-i18next";
import ButtonCustom from "../../components/button/ButtonCustom";
import CarouselPartner from "../../components/views/home-page/carousel-partner/CarouselPartner";
import HeadlineComponent from "../../components/views/home-page/headline-component/HeadlineComponent";
import News from "../../components/views/home-page/news/News";
import OurService from "../../components/views/home-page/our-service/OurService";
import JobOffer from "../../components/views/home-page/receiving-job-offer/JobOffer";
import ChooseUS from "../../components/views/home-page/why-choose-us/ChooseUS";
import BannerComponent from "../../components/banner-component/BannerComponent";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants/url-config";
import { useEffect, useState } from "react";
import { DEFAULT_PAGINATION } from "../../constants/app-constants";
import { dispatch, useSelector } from "../../redux/store";
import { getHighlight, getOnecareer } from "../../redux/slices/career";
import { getWebcontentPage } from "../../redux/slices/webcontent";
import { WebcontentType } from "../../@types/webcontent";
import MetaLayout from "../../components/views/layout/meta-layout";

interface Props extends RouteChildrenProps, LayoutProps {}
interface DescriptionItem {
  title: string;
  content: string;
}

export default function HomePage(props: Props) {
  const { t } = useTranslation();
  const { home } = useSelector((state) => state.webcontent);
  const { careerDetail } = useSelector((state) => state.career);  
  const [metaData, setMetaData] = useState<any>(null);

  // Kiểm tra và phân tích JSON
  const parseJsonContent = (content: string) => {
    try {
      return JSON.parse(content);
    } catch {
      return null;
    }
  };

  useEffect(() => {
    if (Array.isArray(home) && home.length > 0) {
      const dataHome = parseJsonContent(home[0].content);
      if (dataHome) {
        const newMetaData = {
          title: dataHome.banner.title,
          description: dataHome.banner.content,
          ogImage: dataHome.banner.backgroundUrl,
          ogTitle: dataHome.banner.title,
          ogDescription: dataHome.banner.content,
          ogUrl: window.location.href,
        };
        setMetaData(newMetaData);
      }
    dispatch(getOnecareer(dataHome.jobOffer.outstandingJobId.value));
    }
  }, [home]);

  useEffect(() => {
    if (!home) {
      dispatch(getWebcontentPage(WebcontentType.Home));
    }
  }, [home]);

  useEffect(() => {
    dispatch(
      getHighlight({
        pageSize: DEFAULT_PAGINATION.pageSize,
        pageIndex: DEFAULT_PAGINATION.pageIndex,
        isHighlight: true,
      })
    );
  }, []);

  const renderJobOfferSection = () => {
    let content = "", img = "", title = "", description: DescriptionItem[] = [];
    if (careerDetail) {
      const parsedContent = parseJsonContent(careerDetail.content);
      if (parsedContent) {
        content = parsedContent.content;
        img = parsedContent.thumbnail;
        title = parsedContent.name;
        description = parsedContent.detail;
      }
    }

    return (
      <section className="sectionContainer jobOfferSection">
        <JobOffer
          content={description}
          listImageUrl={[]}
          image={img}
          subtitle={content}
          title={title}
          outstandingJobId={{label : title, value : Number(careerDetail.id),}}
        />
      </section>
    );
  };

  if (!home || !careerDetail) return null;

  const dataHome = parseJsonContent(home[0]?.content);

  return (
    <>
      {metaData && <MetaLayout metaData={metaData} />}
      <BannerComponent
        isHomePage
        title={dataHome?.banner.title}
        content={dataHome?.banner.content}
        bannerUrl={dataHome?.banner.backgroundUrl}
        button={
          <Link to={ROUTE_PATHS.Contact}>
            <ButtonCustom title={t("getInTouch")} />
          </Link>
        }
      />
      <section className="sectionContainer">
        <ChooseUS
          content={dataHome?.whyChooseUs.content}
          listImageUrl={dataHome?.whyChooseUs.listImageUrl}
          subtitle={dataHome?.whyChooseUs.subtitle}
          title={dataHome?.whyChooseUs.title}
        />
      </section>
      <section className="sectionContainer ourServiceSection">
        <OurService />
      </section>
      {renderJobOfferSection()}
      <HeadlineComponent />
      <section className="sectionContainer">
        <News title={dataHome?.news.title} content={dataHome?.news.content} />
      </section>
      <CarouselPartner partner={dataHome?.partner} />
    </>
  );
}
