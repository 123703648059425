import { Carousel, Col, Row } from "antd";
import { IChooseHomePage } from "../../../../@types/homePage";
import picture from "../../../../assets/images/home-page/Rectangle 91.png";
import './ChooseUs.scss';
import { useState, useEffect } from "react";

const ChooseUS = ({
  title,
  content,
  listImageUrl,
  subtitle,
}: IChooseHomePage) => {
  const [visibleImages, setVisibleImages] = useState<string[]>([]); 
  const [loadedImagesCount, setLoadedImagesCount] = useState(3); 
  
  useEffect(() => {
    loadMoreImages(); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const loadMoreImages = () => {
    if (loadedImagesCount < listImageUrl.length) {
      const nextCount = Math.min(loadedImagesCount + 3, listImageUrl.length);
      setVisibleImages(listImageUrl.slice(0, nextCount));
      setLoadedImagesCount(nextCount);
    }
  };

  return (
    <div className="containerCustom">
      <Row className="chooseUs" gutter={[{ xs: 0, xl: 80 }, { xs: 24, xl: 0 }]}>
        <Col xs={24} xl={12}>
          <div data-aos="fade-up" data-aos-duration="700" className="chooseUs__image">
            <Carousel 
              className="chooseUs__carousel"
              autoplay
              autoplaySpeed={3000}
              infinite={true}
              effect="fade" 
              dots={true} 
              easing="ease-in-out" 
              beforeChange={loadMoreImages} 
            >
              {visibleImages.map((img, index) => (
                <div key={index} className="shine-effect no-hover">
                  <img src={img} alt={`chooseUs__image_${index}`} loading="lazy" />
                </div>
              ))}
            </Carousel>
          </div>
        </Col>
        <Col xs={24} xl={12}>
          <div data-aos="fade-down" data-aos-duration="700" className="chooseUs__content">
            <h2 className="chooseUs__content--title">{title || 'Why choose us?'}</h2>
            <h3 className="title__content">
              <div dangerouslySetInnerHTML={{ __html: subtitle }} />
            </h3>
            <Row gutter={[{ xs: 0, xl: 140 }, { xs: 24, xl: 32 }]} className="countCompanies">
              {content.map((item, index) => (
                <Col key={index} xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className="count">
                    <div className="count__title">{item.title || 'Default Title'}</div>
                    <div className="count__content">{item.content || 'Default Content'}</div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ChooseUS;
