import logo from '../../src/assets/images/logo.png' 

export const CookieConstants = {
	ACCESS_TOKEN: "ACCESS_TOKEN",
	PERMISSIONS: "PERMISSIONS",
	IS_LOGGED_IN: "IS_LOGGED_IN",
	FULL_NAME: "FULL_NAME",
	AVATAR: "AVATAR",
	ROLE_NAME: "ROLE_NAME", //"http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
	LANGUAGE: "lang",
	USER_ID: "USER_ID"
}

export const LOCAL_STORAGE_KEYS = {
	ACCESS_TOKEN: "ACCESS_TOKEN"
}



export const META_DATA_DEFAULT = {
	TITLE : "AGARI - Top Human Resources Recruitment Services in Vietnam",
	DESCRIPTION:  "AGARI provides professional recruitment services in Vietnam, including talent search, selection, and management for businesses. Discover our solutions to build a high-quality workforce for your company",
	AUTHOR : "AGARI Recruitment Team",
	URL	: "https://agari.com.vn",
	KEY_WORDS : "Human Resources Recruitment, Recruitment, Talent Search, Human Resource Management, Recruitment Services, AGARI, Vietnam",
	IMAGE : logo
}