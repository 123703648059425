import { createSlice } from "@reduxjs/toolkit";
import contatctService from "../../services/contact/contatct.service";
import { notification } from "antd";
import { Contact } from "../../@types/contact";

const initialState = {
  // Khởi tạo trạng thái của contact (nếu cần)
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const postContact = async (data: Contact) => {
 
    await contatctService.post(data);
    // notification.open({
    //   message: "Gửi thành công",
    // });
};

export default contactSlice.reducer;
