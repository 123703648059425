import { useParams } from 'react-router';

import { useEffect, useState } from 'react';
import { dispatch, useSelector } from '../../redux/store';
import "./ServiceDetails.scss";
import ReactHtmlParser from 'react-html-parser';
import BannerComponent from '../../components/banner-component/BannerComponent';
import { useTranslation } from 'react-i18next';
import { getOneEvent } from '../../redux/slices/event';
import BreadcrumbHeader from '../../components/breadcrumb/Breadcrumb';
import { Col, Row, Typography ,Collapse} from "antd";
import moment from 'moment';
import {
  CaretDownOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import { title } from 'process';
const { Panel } = Collapse;
const { Title } = Typography;

type Props = {};

interface DescriptionItem {
  title: string;
  content: string;
  imageUrl : string;
}

const ServiceDetail = (props: Props) => {
  const {eventDetails} = useSelector(state=>state.event)
  const {services} = useSelector(state=>state.event)
  const [arrayKey, setArrayKey] = useState<string | string[]>([]);
  const params = useParams<{id: any}>()
  let content = "";
  let description: DescriptionItem[] = [];

  useEffect(()=> {
    if(params) {
      dispatch(getOneEvent(params.id))
    }
  }, [params])

  useEffect(() => {
    document.body.classList.add('newsDetailBody');
    return () => {
      document.body.classList.remove('newsDetailBody');
    };
  }, []);
  const {t} = useTranslation();

  const handleCollapseChange = (activeKey: string | string[]) => {
    setArrayKey(activeKey);
  };

  const isValidJson = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch {
      return false;
    }
  };

  if (typeof eventDetails.content === "string" && isValidJson(eventDetails.content)) {
    const dataObj = JSON.parse(eventDetails.content);
    content = dataObj.content;
    if(Array.isArray(dataObj.detail)){
      description = dataObj.detail.filter((item:any) => item.title !== "" && item.content !== "");
    }
  }

  const isVideo = (url: string) => {
    return /\.(mp4|webm|ogg|mov)$/i.test(url);
  };

  const isActivePanel = (index: number) => arrayKey.includes((index + 1).toString());

  return (
    <>
    <BannerComponent 
     title={t("serviceDetailPage.banner.title")}
     subtitle={t("serviceDetailPage.banner.subtitle")}
   />
   <BreadcrumbHeader />
   <>
  <div className='serviceDetail containerCustom'>
      <Title level={2} data-aos="fade-up" data-aos-duration="700" className='title serviceDetail__title'>{eventDetails.name}</Title>
      {/* <div className='serviceDetail__date' data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
      {moment(eventDetails.createdAt).format("DD-MM-YYYY")}
      </div> */}
      <div className='serviceDetail__desc' data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
      {eventDetails.description}
        </div>
      <div className='serviceDetail__content' data-aos="fade-up" data-aos-duration="700" data-aos-delay="400">{ReactHtmlParser(isValidJson(eventDetails.content) ? content : eventDetails.content)}
      </div>
      <Col xs={24} xl={18}>
      <Collapse
        ghost
        onChange={handleCollapseChange}
        expandIconPosition="end"
        className="panelJobOffer"
        expandIcon={({ isActive }) => (isActive ? <CaretDownOutlined /> : <CaretRightOutlined />)}
      >
      {description.filter((item) => item.title || item.content).map((item, index) => (
          <Panel
            className="panelJobOffer__item"
            header={
              <div className={`panelJobOffer__header ${isActivePanel(index) ? "active" : ""}`}>
                {item.title}
              </div>
            }
            key={index + 1}
          >
            <div className='panelContent' >
              <div className='panelContent__image'>
               {isVideo(item.imageUrl) ? (
                  <video
                    src={item.imageUrl}
                    autoPlay
                    loop
                    className='panelImage'
                    muted 
                    controls
                    preload='none'
                  />
                ) : (
                  <img className='panelImage' src={item.imageUrl} alt="" />
                )}
                </div>
                <div className='panelContent__content'> {ReactHtmlParser(item.content)} </div>
            </div>
          </Panel>
        ))}
      </Collapse>
     </Col>
     
          {/* <div>
      <h2 className='serviceDetail__morenews'>{t("servicesPage.content.morenews")}</h2>
      <Row gutter={[{ xl: 20 }, { xl: 0, xs: 30 }]} className="serviceDetail__content">
      {services.slice(3).map(services => (
        <Col key={services.id} xs={24} xl={8}>
          <CardComponent
            title={services.name}
            content={services.description}
            picture={services.thumbnail} 
            id={services.id} 
            href={ROUTE_PATHS.ServiceDetail.replace(":id", services.id.toString())}
          />
        </Col>
      ))}
      </Row>
      </div> */}
     
 
      </div>
    </>
   </>
  );
};

export default ServiceDetail;
