import React, { memo, useEffect } from "react";
import "./footer.scss";
import { Col, Divider, Image, Row } from "antd";
import facebookIcon from "../../../../assets/icon/footer/facebook.svg";
import zaloIcon from "../../../../assets/icon/footer/zalo.svg";
import tiktokIcon from "../../../../assets/icon/footer/titktok.svg";
import logoUrl from "../../../../assets/icon/footer/logo.svg";
import call from "../../../../assets/icon/footer/call.svg";
import threads from "../../../../assets/icon/footer/threads.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../constants/url-config";
import { dispatch, useSelector } from "../../../../redux/store";
import { getWebcontentPage } from "../../../../redux/slices/webcontent";
import { WebcontentType } from "../../../../@types/webcontent";
import { IFooterPage } from "../../../../@types/footerPage";
import { ILogo } from "../../../../@types/logoPage";


type Props = {};

const Footer = (props: Props) => {
  const { t } = useTranslation();
  const { footer,logo } = useSelector((state) => state.webcontent);
  let dataFooter: IFooterPage | null = null;
  if (footer && footer.length > 0) {
    dataFooter = JSON.parse(footer[0].content);
  }
  let dataLogo: ILogo | null = null;
  if (logo && logo.length > 0) {
    dataLogo = JSON.parse(logo[0].content);
  }

  useEffect(() => {
    if (!logo) {
      dispatch(getWebcontentPage(WebcontentType.Logo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://sp.zalo.me/plugins/sdk.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const listItem = [
    {
      title: t("homeMenu.content.0"),
      link: ROUTE_PATHS.Home,
    },
    {
      title: t("homeMenu.content.9"),
      link: ROUTE_PATHS.Careers,
    },
    {
      title: (
        <>
          <img src={facebookIcon} alt="facebook" /> {t("homeMenu.content.12")}
        </>
      ),
      link: dataFooter?.linkFacebook ? dataFooter?.linkFacebook : "#",
      external: true,
    },
    {
      title: t("homeMenu.content.1"),
      link: ROUTE_PATHS.About,
    },
    {
      title: t("homeMenu.content.10"),
      link: "https://hrm.agari.com.vn/",
      external: true,
    },
    {
      title: (
        <>
          <img src={tiktokIcon} alt="titok" /> {t("homeMenu.content.13")}
        </>
      ),
      link: dataFooter?.linkTiktok ? dataFooter?.linkTiktok : "#",
      external: true,
    },
    {
      title: t("homeMenu.content.2"),
      link: ROUTE_PATHS.Services,
    },
    {
      title: t("homeMenu.content.11"),
      link: ROUTE_PATHS.Contact,
    },
    {
      title: (
        <>
          <img  src={zaloIcon} alt="facebook" /> {t("homeMenu.content.14")}
        </>
      ),
      link: dataFooter?.linkZalo ? dataFooter?.linkZalo : "#",
      external: true,
    },
    {
      title: "",
      link: "",
    },
    {
      title: "",
      link: "",
    },
    {
      title: (
        <>
          <img style= {{width : '24px'}}src={threads} alt="threads" /> {t("homeMenu.content.15")}
        </>
      ),
      link: dataFooter?.linkThreads ? dataFooter?.linkThreads : "#",
      external: true,
    },
  ];

  useEffect(() => {
    if (!footer) {
      dispatch(getWebcontentPage(WebcontentType.Footer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const zaloChatWidgetHtml = `
  <div class="zalo-chat-widget" data-oaid="3619181341031816894" 
       data-welcome-message="Rất vui khi được hỗ trợ bạn!" 
       data-autopopup="0" data-width="300" data-height="300">
  </div>
`;

  return (
    <>
      <div className="containerCustom footer ">
        <img src={dataLogo?.logoFooter ?dataLogo?.logoFooter: logoUrl} alt="..." />
        <Row>
          <Col xs={24}>
            <Row justify="space-between" align="middle">
              <Col xs={24} xl={16}>

                <div className="address">
                  <div dangerouslySetInnerHTML={{ __html: dataFooter?.address ?? '' }} />
                  {/* {dataFooter?.address ? dataFooter?.address : t("footer.title")} */}
                  </div>
              </Col>

              <Col xs={24} xl={8}>
                <Row gutter={[0, 25]} className="listLink">
                  {listItem?.map((item, index) => {
                    return (
                      <Col className="listLink__item" key={index} xs={8}>
                        {item.external ? (
                          <a href={item.link}>{item.title}</a>
                        ) : (
                          <Link to={item.link}>{item.title}</Link>
                        )}
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Col>
          <div className="line"></div>
        </Row>
        <Row>
          <Col xs={24} xl={12}>
            <div className="privacy">{t("footer.desc")}</div>
          </Col>
          <Col xs={24} xl={12}>
            <div className="copyright">{t("footer.copyright")}</div>
          </Col>
        </Row>
      </div>
        <Row className="footer__img">
        <div className="footer__img--icon">
            <div className="footer__img--icon--layout">
                <a target="_blank" href="https://zalo.me/3619181341031816894" rel="noreferrer">
                    <img src={call} alt="" className="footer__img--call" />
                </a>
            </div>
        </div>
        <div
      dangerouslySetInnerHTML={{
        __html: zaloChatWidgetHtml,
      }}
    />
  </Row>
    </>
  );
};

export default memo(Footer);
