import './tax-document.scss'
import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import BannerComponent from "../../components/banner-component/BannerComponent";
import BreadcrumbHeader from "../../components/breadcrumb/Breadcrumb";
import { ID_EVENT } from "../../constants/app-constants";
import eventService from "../../services/event/event.service";
const TaxDocument = () => {
  const { t } = useTranslation();
  const [data,setData] = useState('');

  const getDataHandle = async ()=>{
    const res = await eventService.getOne(ID_EVENT.TaxDocument);
    setData(res.data.content);
  }
  useEffect(() => {
    if(!data){
        getDataHandle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <BannerComponent
        title={t("taxDocumentPage.banner.title")}
        subtitle={t("taxDocumentPage.banner.subtitle")}
        // bannerUrl={dataService?.banner.backgroundUrl?dataService?.banner.backgroundUrl:""}
      />
      <BreadcrumbHeader />
      <section className = "tax-document">
        {ReactHtmlParser(data)}
      </section>
    </>
  );
}

export default TaxDocument