import React from "react";
import "./Breadcrumb.scss";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb } from "antd";
import useResponsive from "../../hooks/useResponsive";
import { RightOutlined } from "@ant-design/icons";
import i18n from "../../utils/i18n";
const BreadcrumbHeader = () => {
  const { isMobile } = useResponsive();
  const location = useLocation();

  // Check if isMobile exists before rendering
  const updateBreadcrumb = () => {
    const { pathname } = location;
    const pathnames = pathname.split("/").filter((item) => item);
    return (
      <div data-aos="fade-right"
      data-aos-duration="200"
       className="breadcrumb containerCustom">
        <Breadcrumb separator={<RightOutlined />}>
          <Breadcrumb.Item>
            <Link to="/">{i18n.t("home")}</Link>
          </Breadcrumb.Item>
          {pathnames.length > 0 && (
            <Breadcrumb.Item>
              <Link to={`/${pathnames[0]}`}>{i18n.t(`${pathnames[0]}`)}</Link>
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
    );
  };

  return <>{updateBreadcrumb()}</>;
};

export default BreadcrumbHeader;
