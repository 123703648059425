import { ROUTE_DYNAMIC_VARIABLE, ROUTE_PATHS } from "./constants/url-config";
import { PERMISSION } from "./guards/role-guard";

import CareersPage from "./pages/careers/CareersPage";

import HomePage from "./pages/home/home";
import PayrollPage from "./pages/payroll/Payroll";
import SearchPage from "./pages/search/SearchPage";
import SignIn from "./pages/sign-in/sign-in";
import ServicePage from "./pages/service/ServicePage";
import CareersDetail from "./pages/careers-detail/CareersDetail";

import AboutUsPage from "./pages/about-us/AboutUs";
import NewsDetails from "./pages/news-details/NewsDetails";
import ServiceDetail from "./pages/service-detail/ServiceDetails";
import ContactUsPage from "./pages/contacts/Contacts";
import TaxDocument from "./pages/tax-document/TaxDocument";
import Invoice from "./pages/invoice/Invoice";

export interface Route {
  groupIndex?: number;
  href: string;
  exact: boolean;
  component: any;
  title: string;
  hidden?: boolean;
  icon?: any;
  forAdmin?: boolean;
  loginRequired?: boolean;
  permissions: PERMISSION[];
  subMenu?: SubMenu[];
}

interface SubMenu {
  href: string;
  title: string;
}

const anonymousPage: Route[] = [
  {
    href: ROUTE_PATHS.SignIn,
    title: "",
    exact: true,
    component: SignIn,
    hidden: true,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.Search,
    title: "",
    exact: true,
    component: SearchPage,
    hidden: true,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.Careers,
    title: "",
    exact: true,
    component: CareersPage,
    hidden: true,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.Services,
    title: "",
    exact: true,
    component: ServicePage,
    hidden: true,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.TaxDocument,
    title: "",
    exact: true,
    component: TaxDocument,
    hidden: true,
    permissions: [],
  },
  // {
  //   href: ROUTE_PATHS.Invoice,
  //   title: "",
  //   exact: true,
  //   component: Invoice,
  //   hidden: true,
  //   permissions: [],
  // },
  
];

const authorizedPage: Route[] = [
  {
    href: ROUTE_PATHS.Home,
    exact: true,
    component: HomePage,
    title: "Home",
    permissions: [],
    loginRequired: false,
  },
  {
    href: ROUTE_PATHS.Contact,
    exact: true,
    component: ContactUsPage,
    title: "Contact",
    permissions: [],
    loginRequired: false,
  },
  {
    href: ROUTE_PATHS.About,
    exact: true,
    component: AboutUsPage,
    title: "About",
    permissions: [],
    loginRequired: false,
  },
  {
    href: ROUTE_PATHS.Payroll,
    exact: true,
    component: PayrollPage,
    title: "Payroll",
    permissions: [],
    loginRequired: false,
  },
  {
    href: ROUTE_PATHS.CareersDetail,
    exact: true,
    component: CareersDetail,
    title: "CareersDetail",
    permissions: [],
    loginRequired: false,
  },
  {
    href: ROUTE_PATHS.NewsDetail,
    exact: true,
    component: NewsDetails,
    title: "NewsDetails",
    permissions: [],
    loginRequired: false,
  },
  {
    href: ROUTE_PATHS.ServiceDetail,
    exact: true,
    component: ServiceDetail,
    title: "ServiceDetail",
    permissions: [],
    loginRequired: false,
  },
  {
    href: ROUTE_PATHS.Careers,
    title: "Careers",
    exact: true,
    component: CareersPage,
    hidden: true,
    permissions: [],
    loginRequired: false,
  },
];

export const routes: Route[] = [...anonymousPage, ...authorizedPage];
