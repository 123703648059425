import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { CareerState } from "../../@types/career";
import careerService from "../../services/career/career.service";
import { ParamsType } from "../../@types/paramsType";

// ----------------------------------------------------------------------

export const getcareer = createAsyncThunk(
  "get/getcareer",
  async (params: ParamsType) => {
    const { data } = await careerService.get(params);
    return data;
  }
);

export const getHighlight= createAsyncThunk(
  "get/getCareerHighlight",
  async (params: ParamsType) => {
    const { data } = await careerService.get(params);
    return data;
  }
);

export const getOnecareer = createAsyncThunk(
  "get/getOnecareer",
  async (id: string | number) => {
    const { data } = await careerService.getOne(id);
    return data;
  }
);
const initialState: CareerState = {
  careerList: [],
  careerDetail: {
    id: 0,
    brand_id: 0,
    province_id: 0,
    name: "",
    field: "",
    jobType: "",
    content: "",
    description: "",
    createdAt:"",
  },
  careersCount: 0,
  careerHighlight: [],
};

const slice = createSlice({
  name: "career",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getcareer.fulfilled, (state, action) => {
      state.careerList = action.payload.items;
      state.careersCount = action.payload.totalRow;
    });
    builder.addCase(getOnecareer.fulfilled, (state, action) => {
      state.careerDetail = action.payload;
    })
    builder.addCase(getHighlight.fulfilled, (state, action) => {
      state.careerHighlight = action.payload.items;

    })
    
    ;
  },
});
export default slice.reducer;
