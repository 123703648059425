import { Col, Row, Typography } from "antd";
import "./OurTeam.scss";
import ourTeam1 from "../../../../assets/images/About/ourteamImg1.png";
import ourTeam2 from "../../../../assets/images/About/ourteamImg2.png";
import ourTeam3 from "../../../../assets/images/About/ourteamImg3.png";
import ourTeam4 from "../../../../assets/images/About/ourteamImg4.png";
import ourTeam5 from "../../../../assets/images/About/ourteamImg5.png";
import ourTeam6 from "../../../../assets/images/About/ourteamImg6.png";
import ourTeam7 from "../../../../assets/images/About/ourteamImg7.png";
import useResponsive from "../../../../hooks/useResponsive";
import { useTranslation } from "react-i18next";
import { IOurTeamPage } from "../../../../@types/aboutPage";
const { Title, Text } = Typography;
type Props = {};

const OurTeam = ({
  content,
  listImageUrl,
  title,
}: IOurTeamPage) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const img1 = listImageUrl[0] ? listImageUrl[0] : ourTeam1;
  const img2 = listImageUrl[1] ? listImageUrl[1] : ourTeam2;
  const img3 = listImageUrl[2] ? listImageUrl[2] : ourTeam3;
  const img4 = listImageUrl[3] ? listImageUrl[3] : ourTeam4;
  const img5 = listImageUrl[4] ? listImageUrl[4] : ourTeam5;
  const img6 = listImageUrl[5] ? listImageUrl[5] : ourTeam6;
  const img7 = listImageUrl[6] ? listImageUrl[6] : ourTeam7;
  return (
    <div id="test" className="ourTeam " >
      <div data-aos="fade-down"
        data-aos-anchor-placement="top-center" data-aos-duration="700">
        <Title className="title ourTeam__title" level={2}>
          {title ? title : t("aboutPage.ourTeam.title")}
        </Title>
        <div className="ourTeam__desc"
          dangerouslySetInnerHTML={{ __html: content }} >
        </div>

      </div>
      <div className="ourTeam__content">
        <Row className="ourTeam__content__image" >
          <Col  xs={24} sm={12} md={8} lg={7} >
            <Row style={{height : '100%'}}>
              <Col className="ourTeam__content__image--top" span={24} data-aos="fade-down" data-aos-anchor-placement="top-center" data-aos-duration="700">
                <div className="image-container">
                  <div className="shine-effect" >
                    <img src={img1} alt="" />
                  </div>
                </div>
              </Col>
              <Col span={24} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="700">
                <div className="image-container">
                  <div className="shine-effect">
                    <img src={img3} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col  xs={24} sm={12} md={8} lg={10}>
            <Row >
              <Col
                className="ourTeam__content__image--top ourTeam__content__image__right"
                span={24} data-aos="fade-down" data-aos-anchor-placement="top-center" data-aos-duration="700"
              >
                <div className="image-container" >
                  <div className="shine-effect"  >
                    <img src={img2} alt=""  />
                  </div>
                </div>
              </Col>
            </Row>
            <Row data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="700">
              <Col className="ourTeam__content__image__right" xs={24} sm={24} md={12} lg={12} >
                <div className="image-container">
                  <div className="shine-effect" >
                    <img src={img4} alt="" />
                  </div>
                </div>
              </Col>
              <Col className="ourTeam__content__image__right" xs={24} sm={24} md={12} lg={12} >
                <div className="image-container">
                  <div className="shine-effect" >
                    <img src={img6} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col  xs={24} sm={12} md={8} lg={7}>
            <Row style={{height : '100%'}}>
              <Col className="ourTeam__content__image--top" span={24} data-aos="fade-down" data-aos-anchor-placement="top-center" data-aos-duration="700">
                <div className="image-container">
                  <div className="shine-effect" >
                    <img src={img5} alt="" />
                  </div>
                </div>
              </Col>
              <Col span={24} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="700">
                <div className="image-container">
                  <div className="shine-effect" >
                    <img src={img7} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default OurTeam;
