import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DistrictState } from "../../@types/district";
import { ParamsType } from "../../@types/paramsType";
import districtApi from "../../services/district.api";

export const getDistrictList = createAsyncThunk(
  "district/getList",
  async (params: ParamsType, { dispatch }) => {
    const { data } = await districtApi.getList(params);
    return data;
  }
);

const initialState: DistrictState = {
  districtList: [],
};

const slice = createSlice({
  name: "district",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDistrictList.fulfilled, (state, action) => {
      state.districtList = action.payload.items;
    });
  },
});

// export const {} = slice.actions

export default slice.reducer;
