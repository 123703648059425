import React from "react";
import "./BannerComponent.scss";
import banner from "../../assets/images/layout.png";

type Props = {
  title?: string;
  subtitle?: string;
  content?: string;
  button?: any;
  isHomePage?: boolean;
  bannerUrl?: string;
};

const BannerComponent = ({
  title,
  subtitle,
  content,
  button,
  isHomePage,
  bannerUrl,
}: Props) => {
  const backgroundImage = bannerUrl ? bannerUrl : banner;
  
  return (
    <>
      <div className="bannerContainer" style={{
        backgroundImage: `url(${backgroundImage})`,
      }}>
        <div className={`bannerContent ${isHomePage && 'homeBanner'}`}>
          <h2 className="bannerContent__subtitle">
            {subtitle?.toUpperCase()}
          </h2>
          <h1 className="bannerContent__title">{title}</h1>
          <div className="bannerContent__content">{content}</div>
          {button}
        </div>
      </div>
    </>
  );
};

export default BannerComponent;
