import { useParams } from 'react-router';
import { useEffect } from 'react';
import { dispatch, useSelector } from '../../redux/store';
import ReactHtmlParser from 'react-html-parser';
import BannerComponent from '../../components/banner-component/BannerComponent';
import { useTranslation } from 'react-i18next';
import { getNewsEvent, getOneEvent } from '../../redux/slices/event';
import BreadcrumbHeader from '../../components/breadcrumb/Breadcrumb';
import { Col, Row, Typography } from "antd";
import CardComponent from '../../components/card-component/CardComponent';
import { ROUTE_PATHS } from '../../constants/url-config';
import moment from 'moment';
import "./NewsDetails.scss";
import { NEWS } from '../../constants/app-constants';
const { Title } = Typography;
type Props = {
};

const NewsDetails = (props: Props) => {
  const params = useParams<{id: any}>()
  const {eventDetails} = useSelector(state=>state.event)
  const {news} = useSelector(state=>state.event)

  useEffect(()=> {
    if(params) {
      dispatch(getOneEvent(params.id))
    }
  }, [params])

  useEffect(() => {
    dispatch(
      getNewsEvent({
        pageIndex: 1,
        pageSize: 100,
        eventType: NEWS,
      })
    );
    document.body.classList.add('newsDetailBody');
    return () => {
      document.body.classList.remove('newsDetailBody');
    };
  }, []);

  const {t} = useTranslation();

  return (
    <>
      <BreadcrumbHeader />
      <div className='newsDetail containerCustom'>
        <Title level={2} data-aos="fade-up" data-aos-duration="700" className='title newsDetail__title'>{eventDetails.name}</Title>
        <div className='newsDetail__time' data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">{moment(eventDetails.createdAt).format("DD-MM-YYYY")}</div>
        <div className='newsDetail__date' data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
          {eventDetails.description}
        </div>
        <div className='newsDetail__desc' data-aos="fade-up" data-aos-duration="700" data-aos-delay="400">{ReactHtmlParser(eventDetails.content)}</div>

        {/* Thêm thẻ video */}
        {/* <div className='newsDetail__video' data-aos="fade-up" data-aos-duration="700" data-aos-delay="500">
          <video controls style={{ maxWidth: '1000px', width : '100%'}}>
            <source src={videoUrl} type="video/mp4" />
          </video>
        </div> */}

        <div>
          <h2 className='newsDetail__morenews' data-aos="zoom-in"  data-aos-duration="700">{t("servicesPage.content.morenews")}</h2>
          <Row gutter={[{ xl: 20 }, { xl: 0, xs: 30 }]} className="newsDetail__content">
            {news.slice(0, 3).map(news => (
              <Col key={news.id} xs={24} xl={8} data-aos="zoom-in-up" data-aos-duration="700" data-aos-delay="300">
                <CardComponent
                  title={news.name}
                  content={news.description}
                  picture={news.thumbnail} 
                  id={news.id} 
                  href={ROUTE_PATHS.NewsDetail.replace(":id", news.id.toString())}
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
};

export default NewsDetails;
