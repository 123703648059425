import { ApplyJob } from "../../@types/applyJob";
import { postAsync } from "../http-client";

const applyJobApi = {
  post: (data: ApplyJob) => {
    return postAsync("/career/apply", data);
  },
};

export default applyJobApi;
